import { ChangeDetectorRef, Component, OnInit, input } from '@angular/core';
import { ParamFilters } from '@app/models/filtermodels/ParamFilters';

@Component({
    selector: 'clever-preisealarmselectedfilters',
    templateUrl: './preisealarmselectedfilters.component.html',
    styleUrls: ['./preisealarmselectedfilters.component.scss'],
    standalone: false
})
export class PreisealarmselectedfiltersComponent implements OnInit {
    readonly filter = input<ParamFilters>(undefined);
    readonly mode = input<string>(undefined);
    list: string[] = [];
    slice: number = 5;
    constructor(
        protected cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.list = [];
        this.cdr.detectChanges();
        this.checkPrc();
        this.checkInclusiveListServiceOffer();
        this.checkflightCarrierList();
        this.checktransferlist();
        this.checkarrivalAirportList();
        this.checkreturnTime();
        this.checkdepartureTime();

        this.checkMeerblibk();
        this.checkMeerseite();
        this.checklocationCode();
        this.checkboardTypeList();
        this.checkdirektflug();
        this.checktarifclass();
        this.checkbookingclass();
        this.checkTravelClass();
        this.checkinclusiveList();
        this.checkFacilityList();
        this.checkroomTypeList();
        this.checkroomIdList();
        this.checktourOperatorList();
        this.checkhotelChainList();
    }

    showList(vl) {
        this.slice = vl;
    }

    checkPrc(): void {
        const filter = this.filter();
        if (filter?.minPricePerPerson > 0) {
            this.list.push('min ' + filter?.minPricePerPerson + ' € p.P.');
            this.cdr.detectChanges();
        }
        if (filter?.maxPricePerPerson > 0) {
            this.list.push('max ' + filter?.maxPricePerPerson + ' € p.P.');
            this.cdr.detectChanges();
        }
    }

    checkInclusiveListServiceOffer(): void {
        const filter = this.filter();
        if (filter?.inclusiveListServiceOffer?.length > 0) {
            for (let i = 0; i < filter?.inclusiveListServiceOffer.length; i++) {
                this.list.push(filter?.inclusiveListServiceOffer[i].name);
            }
        }
    }

    checkMeerblibk(): void {
        if (this.filter()?.zommermitmeerblick) {
            this.list.push('Meerblick');
        }
    }

    checkMeerseite(): void {
        if (this.filter()?.meerseite) {
            this.list.push('Meerseite');
        }
    }

    checkboardTypeList(): void {
        const filter = this.filter();
        if (filter?.boardTypeList?.length > 0) {
            for (let i = 0; i < filter?.boardTypeList.length; i++) {
                this.list.push(filter?.boardTypeList[i].name);
            }
        }
    }

    checklocationCode(): void {
        const filter = this.filter();
        if (filter?.locatedList?.length > 0) {
            for (let i = 0; i < filter?.locatedList.length; i++) {
                this.list.push(filter?.locatedList[i].name);
            }
        }
    }

    checktransferlist(): void {
        const filter = this.filter();
        if (filter?.transferlist?.length > 0) {
            for (let i = 0; i < filter?.transferlist.length; i++) {
                if (filter?.transferlist[i] === 'MT') {
                    this.list.push('mit Transfer');
                } else if (filter?.transferlist[i] === 'OT') {
                    this.list.push('ohne Transfer');
                } else if (filter?.transferlist[i] === 'MW') {
                    this.list.push('Mietwagen');
                } else if (filter?.transferlist[i] === 'ZG') {
                    this.list.push('Zug zum Flug');
                }
            }
        }
    }

    checkdirektflug(): void {
        const filter = this.filter();
        if (filter?.flight?.directness === 'N') {
            this.list.push('Direktflug');
        } else if (filter?.flight?.stopover === '1') {
            this.list.push('max 1 Stopp');
        } else if (filter?.flight?.stopover === '2') {
            this.list.push('max 1 Stopp');
        }
    }

    checktarifclass(): void {
        const filter = this.filter();
        if (filter?.flight?.tariff === 'SPAR') {
            this.list.push('Sparpreis');
        }
        if (filter?.flight?.tariff === 'FLEX') {
            this.list.push('Flexpreis');
        }
    }

    checkbookingclass(): void {
        const filter = this.filter();
        if (filter?.flight?.bookingClass === '1') {
            this.list.push('1. Klasse');
        }
        if (filter?.flight?.bookingClass === '2') {
            this.list.push('2. Klasse');
        }
    }

    checkTravelClass(): void {
        const filter = this.filter();
        if (filter?.flight?.travelClass === 'economy') {
            this.list.push('Flugklasse: Economy');
        } else if (filter?.flight?.travelClass === 'premium') {
            this.list.push('Flugklasse: Premium Eco');
        } else if (filter?.flight?.travelClass === 'business') {
            this.list.push('Flugklasse: Business');
        } else if (filter?.flight?.travelClass === 'first') {
            this.list.push('Flugklasse: First');
        }
    }

    checkinclusiveList(): void {
        const filter = this.filter();
        if (filter?.inclusiveList?.length > 0) {
            for (let i = 0; i < filter?.inclusiveList.length; i++) {
                this.list.push(filter?.inclusiveList[i].name);
            }
        }
    }

    checkFacilityList(): void {
        const filter = this.filter();
        if (filter?.facilityList?.length > 0) {
            for (let i = 0; i < filter?.facilityList.length; i++) {
                this.list.push(filter?.facilityList[i].name);
            }
        }
    }

    checkroomTypeList(): void {
        const filter = this.filter();
        if (filter?.roomTypeList?.length > 0) {
            for (let i = 0; i < filter?.roomTypeList.length; i++) {
                this.list.push(filter?.roomTypeList[i].name);
            }
        }
    }

    checkroomIdList(): void {
        const filter = this.filter();
        if (filter?.roomIdList?.length > 0) {
            for (let i = 0; i < filter?.roomIdList.length; i++) {
                this.list.push(filter?.roomIdList[i].name + ' (' + filter?.roomIdList[i].code + ')');
            }
        }
    }

    checktourOperatorList(): void {
        const filter = this.filter();
        if (filter?.tourOperatorList?.length > 0) {
            for (let i = 0; i < filter?.tourOperatorList.length; i++) {
                this.list.push(filter?.tourOperatorList[i].code);
            }
        }
    }

    checkarrivalAirportList(): void {
        const filter = this.filter();
        if (filter?.arrivalAirportList?.length > 0) {
            for (let i = 0; i < filter?.arrivalAirportList.length; i++) {
                this.list.push('Ziel ' + filter?.arrivalAirportList[i].name + ' ' + filter?.arrivalAirportList[i].code);
            }
        }
    }

    checkhotelChainList(): void {
        const filter = this.filter();
        if (filter?.hotelChainList?.length > 0) {
            for (let i = 0; i < filter?.hotelChainList.length; i++) {
                this.list.push(filter?.hotelChainList[i].name);
            }
        }
    }

    checkflightCarrierList(): void {
        const filter = this.filter();
        if (filter?.flightCarrierList?.length > 0) {
            for (let i = 0; i < filter?.flightCarrierList.length; i++) {
                this.list.push(filter?.flightCarrierList[i].name);
            }
        }
    }

    checkdepartureTime(): void {
        let text = '';
        const filter = this.filter();
        if (filter?.mindepartureTime > 0 && filter?.mindepartureTime < 1440) {
            text = this.transformMinute(filter?.mindepartureTime);
        }
        if (filter?.maxdepartureTime > 0 && filter?.maxdepartureTime < 1440) {
            if (text?.length > 0) {
                text += '-' + this.transformMinute(filter?.maxdepartureTime);
            } else {
                text = 'vor ' + this.transformMinute(filter?.maxdepartureTime);
            }
        } else if (text?.length > 0) {
            text = 'nach ' + text;
        }
        if (text?.length > 0) {
            this.list.push('Hinflugzeit ' + text);
        }
    }

    transformMinute(value: number): string {
        const hours = ('00' + Math.floor(value / 60)).slice(-2);
        const minutes = ('00' + Math.floor(value % 60)).slice(-2);
        return hours + ':' + minutes;
    }

    checkreturnTime(): void {
        let text = '';
        const filter = this.filter();
        if (filter?.minreturnTime > 0 && filter?.minreturnTime < 1440) {
            text = this.transformMinute(filter?.minreturnTime);
        }
        if (filter?.maxreturnTime > 0 && filter?.maxreturnTime < 1440) {
            if (text?.length > 0) {
                text += '-' + this.transformMinute(filter?.maxreturnTime);
            } else {
                text = 'vor ' + this.transformMinute(filter?.maxreturnTime);
            }
        } else if (text?.length > 0) {
            text = 'nach ' + text;
        }
        if (text?.length > 0) {
            this.list.push('Rückflugzeit ' + text);
        }
    }
}
