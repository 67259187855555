import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'tohttps',
    standalone: false
})
export class Converttohttps implements PipeTransform {
    constructor (
    ) {}

    transform (value: any): string {
        return value?.replace(/http:\/\//g, 'https://');
    }
}
