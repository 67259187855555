<div class="cc1">
    <ng-container *ngFor="let set of list| slice:0:slice">
        <div class="myfltrs">
            {{set}}
        </div>
    </ng-container>

    <ng-container *ngIf="this.list.length > 5">
        <ng-container *ngIf="slice < this.list.length">
            <button (click)="showList(this.list.length + 1)" class="ml">alle {{this.list.length}} anzeigen</button>
        </ng-container>
    
        <ng-container *ngIf="slice > this.list.length">
            <button (click)="showList(5)" class="ml">weniger anzeigen</button>
        </ng-container>
    </ng-container>
</div>