import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'cleanText',
    standalone: false
})
export class CleanTextPipe implements PipeTransform {
    constructor () {}

    transform (value: any): string {
        return value?.replaceAll('<br />', '').replaceAll('&uuml;', 'ü').replaceAll('&auml;', 'ä');
    }
}
