import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable(
    { providedIn: 'root' }
)
export class DateService {
    calendars: BehaviorSubject<any []>;

    constructor() {
        this.calendars = new BehaviorSubject([]);
    }

    async initCals() {
        const big_calendars = [];
        for (let i = 0; i < 18; i++) {
            const tmp_d = moment().startOf('month').add(i, 'months').format('YYYY-MM-DD');
            const tmp_month = this.initMonth(tmp_d);
            tmp_month.header = moment(tmp_d).format('MMMM, YYYY');
            tmp_month.class = 'cal_' + moment(tmp_d).format('YYYY-MM')

            big_calendars.push(tmp_month);
        }
        this.calendars.next(big_calendars);
    }

    getBigCalendar(): Observable<any> {
        return this.calendars.asObservable();
    }

    initMonth(date: string): any {
        moment.locale('de');

        const month = {
            w_0: [],
            w_1: [],
            w_2: [],
            w_3: [],
            w_4: [],
            w_5: [],
        };

        const tmp_days = Array.from({ length: moment(date).daysInMonth() }, (x, i) => moment(date).startOf('month').add(i, 'days').format('YYYY-MM-DD'));
        let start = moment(tmp_days[0]).day();
        if (start > 0) {
            start -= 1;
        } else {
            start = 6;
        }
        // console.log(start)
        for (let i = 0; i < start; i++) {
            tmp_days.unshift(null);
        }
        const end = 42 - tmp_days?.length;
        // console.log(end)
        for (let i = 0; i < end; i++) {
            tmp_days.push(null);
        }
        // console.log(tmp_days)
        for (let i = 0; i < tmp_days.length; i++) {
            if (tmp_days[i]) {
                if (month['w_' + Math.floor(i / 7)]?.length === 0) {
                    month['w_' + Math.floor(i / 7)] = Array.apply(null, Array(7));
                }
                month['w_' + Math.floor(i / 7)].splice(i % 7, 1, {
                    id: tmp_days[i],
                    value: tmp_days[i].slice(-2)
                });
            }
        }
        // console.log((Date.now() - strd) / 1000);
        // console.log(month);

        return month;
    }
}
