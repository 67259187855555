import { PipeTransform, Pipe } from '@angular/core';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { EncryptionService } from '@app/services/encyption.service';

@Pipe({
    name: 'cacheResource',
    standalone: false
})
export class CacheResourcePipe implements PipeTransform {
    constructor(
        private encryption: EncryptionService,
        private guardService: AuthGuardService
    ) { }

    transform(url, size?: string): string {
        if (url?.match(/https.*i.*.giatamedia.com.*/)) {
            const tmp_url = 'https://cdn.acvis.de/v1/getimage?params=' +
                encodeURIComponent(this.encryption.encryptimgurl({ url: url, partnerid: this.guardService.getPartnerId() }));
            return tmp_url;
        } else {
            return url;
        }
    }
}
