import { Location } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'cleverfragment',
    standalone: false
})
export class CleverFragmentPipe implements PipeTransform {
    constructor (
        private location: Location
    ) {}

    transform (value: any): string {
        return value?.replace(/\$\$cleverTag\$\$/g, this.location.path());
    }
}
