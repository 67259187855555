import { OnChanges, Directive, ElementRef, SimpleChanges, input } from '@angular/core';

@Directive({
    selector: '[ng-focus]',
    standalone: false
})
export class AutofocusDirective implements OnChanges {
    public readonly appAutoFocus = input<boolean>(undefined);

    constructor (private el: ElementRef) {
    }

    ngOnChanges (ev: SimpleChanges) {
        if (ev['appAutoFocus'] && this.appAutoFocus()) {
            this.el.nativeElement.focus();
            if (this.el.nativeElement.select) {
                this.el.nativeElement.select();
            }
        }
    }
}
