import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'linkifyEdom',
    standalone: false
})
export class LinkifyEdomPipe implements PipeTransform {
    constructor () {}

    transform (value: any): string {
        return value?.replace('href="', 'class="linkified" href="https://image.e-domizil.de/');
    }
}
