import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ComponentRef, OnChanges, OnDestroy, OnInit, Renderer2, input, viewChild, output, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'clever-rightmodal',
    templateUrl: './rightmodal.component.html',
    styleUrls: ['./rightmodal.component.scss'],
    animations: [
        trigger('modalState', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('150ms', style({ transform: 'translateX(0%)' })),
            ])
        ])
    ],
    standalone: false
})
export class RightmodalComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    readonly triggerCloseModal = output<boolean>();
    readonly cmpRef = input<ComponentRef<any>>(undefined);
    readonly type = input<string>(undefined);
    readonly id = input<string>(undefined);

    readonly target = viewChild('target', { read: ViewContainerRef });

    private isViewInitialized: boolean = false;

    constructor(
        protected render: Renderer2
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            this.render.addClass(document.body, 'model-open');
        });
    }

    ngOnDestroy(): void {
        this.render.removeClass(document.body, 'model-open');
        const cmpRef = this.cmpRef();
        if (cmpRef) {
            cmpRef.destroy();
        }
    }

    ngOnChanges(): void {
        this.updateComponent();
    }

    ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.updateComponent();
    }

    closeModal() {
        this.render.removeClass(document.body, 'model-open');
        this.triggerCloseModal.emit(true);
    }

    back(ev) {
        ev.stopPropagation();
        this.closeModal();
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return;
        }
        this.target().insert(this.cmpRef().hostView);
    }
}
