<div class="modal-wrapper">
    <div class="modal-body" [@modalState]>
        <div class="modal-header">
            <button class="hbtn" (click)="back($event)" matRipple>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="m-content customscroll" id="rigth-modal-content">
            <ng-template #target></ng-template>
        </div>
    </div>
</div>