import { PipeTransform, Pipe } from '@angular/core';
const urlPattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;

@Pipe({
    name: 'linkify',
    standalone: false
})
export class LinkifyPipe implements PipeTransform {
    constructor () {}

    transform (value: any, standardcolor: boolean = true): string {
        value = value?.replace(/<a[^>]*>(.*?)<\/a>/gim, '$1');
        if (standardcolor) {
            return value?.replace(urlPattern, '<a href="$&" style="color: var(--cl-text-color)" target="_blank">$&</a>');
        } else {
            return value?.replace(urlPattern, '<a href="$&" target="_blank">$&</a>');
        }
    }
}
