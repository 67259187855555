import { Component, OnInit, Input, input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: false
})
export class AvatarComponent implements OnInit {
    @Input() data: string;
    readonly small = input<boolean>(undefined);

    constructor () { }

    ngOnInit (): void {
        // console.log(this.data);
    }
}
